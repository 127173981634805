import React, {FC} from 'react';
import './styles.scss';

interface ISwitch {
  text1: string;
  text2: string;
  active: boolean;
  onChange: (val: string) => void;
  className?: string;
}

const Switch: FC<ISwitch> = ({text1, text2, active, onChange, className}) => {
  return (
    <div className={`d-flex switch-wrapper ${className ? className : ''}`}>
      <div className={`${active ? 'active' : ''}`} onClick={() => onChange(text1)}>
        {text1}
      </div>
      <div className={`${!active ? 'active' : ''}`} onClick={() => onChange(text2)}>
        {text2}
      </div>
    </div>
  );
};

export default Switch;
